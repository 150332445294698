export const environment = {
    production: false,
    firebase : {
      apiKey: "AIzaSyAcB-d8_vxoWv2GVRdPBpmhFbpTIv_HnvM",
      authDomain: "nucamp-12c45.firebaseapp.com",
      databaseURL: "https://nucamp-12c45.firebaseio.com",
      projectId: "nucamp-12c45",
      storageBucket: "nucamp-12c45.appspot.com",
      messagingSenderId: "858299088608"
    },
    functionsURL: "https://us-central1-nucamp-12c45.cloudfunctions.net",
    gcpAPI: "AIzaSyAcB-d8_vxoWv2GVRdPBpmhFbpTIv_HnvM",
    gcpMapsAPI: "d8_vxoWv2GVRdPBpmhFbpTIv_HnvM",
    stripePublishable: "pk_test_czayctNHqOce521CzQKzPZgV",
    // handsontable: "8ac35-96084-14910-b4330-8181b",
    handsontable: "f39f4-23ebd-cb875-a010e-d0f2b",
    mauticURL: "https://mauticstg.nucamp.co",
    earlybirdDays: 14,
    communityPopup: 30000,
    bootcampdetailPopup: 30000,
    livesession: { switch: 'off', id: "09c85105.2e810908" },
    logrocket: { switch: 'off', id: 'xcvrvw/nucamp' },
    enableGRDP: true,
    chatProvider: "crisp",
    googleTagManagerId: "GTM-W5PC2G3",
    reCAPTCHA: "6Lf6a-wgAAAAAEwDHCUY2Ht_zhT55iHIw2mWFVWk",
    crispChatWebsiteID: "f2d1e610-d3c4-4113-937e-4f8f261feba1"
}
